import { useEffect, useState } from "react"

export const HtmlControls = ({ onChange }: any) => {
  const [ state, setState ] = useState<any>({slices: 10, points: 20})
  useEffect(() => {
    onChange(state)
  }, [state, onChange])

  function handleChangeSlices(e: any) {
    setState((prev: any) => ({...prev, slices: Number(e.target.value)}))
  }
  function handleChangePoints(e: any) {
    setState((prev: any) => ({...prev, points: Number(e.target.value)}))
  }
  return (
    <div className="HtmlControls">
      <label>Slices:</label>
      <input type="range" value={state.slices} min={2} max={20} step={2} onChange={ handleChangeSlices } />
      <br/>
      <label>Points:</label>
      <input type="range" value={state.points} min={10} max={60} step={10} onChange={ handleChangePoints } />
    </div>
  )
}