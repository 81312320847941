import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import * as THREE from 'three'
import { generateBranch, Point } from './generator'

function Line({ points }: any) {
  const ref = useRef<any>()
  useLayoutEffect(() => {
    ref.current.geometry.setFromPoints(points.map((point: any) => new THREE.Vector3(...point)))
  }, [points])
  return (
    <line ref={ref}>
      <bufferGeometry />
      <lineBasicMaterial color="hotpink" />
    </line>
  )
}


type Segment = {
  id: number,
  points: Point[]
}

export const Gene = ({ position, slices, points }: any) => {
  const [alpha, setAlpha ] = useState<number>(0)
  const [leg0, setLeg0] = useState<Point[]>([])
  const [leg1, setLeg1] = useState<Point[]>([])
  const [leg2, setLeg2] = useState<Point[]>([])
  const [leg3, setLeg3] = useState<Point[]>([])

  const segments = useMemo(() => {
    return [leg0, leg1, leg2, leg3].map((leg, index) => {
      const points = leg.slice(0, Math.floor(leg.length * alpha))
      return {id: index, points}
    })
  }, [alpha, leg0, leg1, leg2, leg3])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (alpha < 1) {
        setAlpha(prev => prev + 0.05)
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [alpha])

  useEffect(() => {
    setLeg0(generateBranch({position, dirX: -1, dirY:  1, count: points, slices}))
    setLeg1(generateBranch({position, dirX: -1, dirY: -1, count: points, slices}))
    setLeg2(generateBranch({position, dirX:  1, dirY: -1, count: points, slices}))
    setLeg3(generateBranch({position, dirX:  1, dirY:  1, count: points, slices}))
  }, [position, slices, points])

  return (
    <>
      {segments.map(segment => (
        <Line key={ segment.id } points={ segment.points } />
      ))}
    </>
  )
}