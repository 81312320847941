type Vec3D = [number, number, number]

function randomUnitVec3D(): Vec3D {
  const orientX = Math.random() < 0.5 ? -1 : 1
  const orientY = Math.random() < 0.5 ? -1 : 1
  const orientZ = Math.random() < 0.5 ? -1 : 1
  return randomVec3D([orientX, orientY, orientZ])
}
function randomVec3D([xDelta, yDelta, zDelta]: Vec3D): Vec3D {
  const x = Math.random() * xDelta
  const y = Math.random() * yDelta
  const z = Math.random() * zDelta
  const length = Math.sqrt(x*x + y*y + z*z)
  return [x/length, y/length, z/length]
}
function normalizePoint(vec: Vec3D) {
  const [x, y, z ] = vec
  const length = Math.sqrt(x*x + y*y + z*z)
  vec[0] = x/length
  vec[1] = y/length
  vec[2] = z/length
}
function scalePoint(p: Point, scale: number): Point {
  return [p[0]*scale, p[1]*scale, p[2]*scale]
}

export type Point = [number, number, number]
type Props = {
  position: Point
  dirX?: number
  dirY?: number
  slices?: number // slices  o.O
  count?: number // number of particles
}

export const generateBranch = ({ position, dirX=0, dirY=0, count=50, slices=10}: Props): Point[] => {
  const points: Point[] = []
  let branch: Point = [...position]
  for (let i = 0; i < slices; i++) {
    const branchMove = randomVec3D([1,dirY,1])

    branch[0] += branchMove[0] + dirX
    branch[1] += branchMove[1]
    branch[2] += branchMove[2]
    normalizePoint(branchMove)
    //
    for (let j = 0; j < count; j++) {
      const v = randomUnitVec3D()
      const p: Point = [branch[0] + v[0], branch[1] + v[1], branch[2] + v[2]]
      points.push(scalePoint(p, 0.2))
    }
  }
  return points
}