import { Canvas } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { Gene } from './components/Gene'
import { HtmlControls } from './components/HtmlControls'
import { useState } from 'react'

export default function App() {
  const [ state, setState ] = useState<any>({slices: 10, points: 10})

  function handleChange(state: any) {
    setState(state)
  }
  return (
    <>
      <HtmlControls onChange={handleChange} />
      <Canvas>
        <OrbitControls enabled autoRotate autoRotateSpeed={0.2} rotateSpeed={2} />
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <group>
          <Gene {...state} position={ [0, -15, 0] } />
          <Gene {...state} position={ [0, 0, 0] } />
          <Gene {...state} position={ [0, 15, 0] } />
        </group>
      </Canvas>
    </>
  )
}
